import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getUserIsAuth } from 'store/selectors/user';
import { useAddFavoriteCardMutation, useRemoveFavoriteCardMutation } from 'store/services/favorites';
import useEntityClone from 'store/services/helperHooks/useEntityClone';
import { useRemoveLearningCommunitiesMutation } from 'store/services/learningCommunities';

import getStatus from 'Components/Cards/LearningCommunities/getStatus';
import NewAdminFooter from 'Components/NewCards/LearningCommunityCard/NewAdminFooter';

import noBackground from 'assets/images/noBackground.png';
import { PAYMENT_TYPES, STRING_UTILS, USER_COURSE_TYPES, USER_PERMISSION } from 'common';
import ENTITY_TYPES, { getEntityName, getOldEntitiesType, getOrgPrice } from 'common/entity-types';
import CARD_OPTIONS from 'constants/DropdownOptions/card';

import Button from 'style-guide/Button';
import DividerWithText from 'style-guide/DividerWithText';
import DropdownV2 from 'style-guide/DropdownV2';
import { NewHeart, NewHeartFilled, Settings } from 'style-guide/Icons';
import Image from 'style-guide/Image';
import Label from 'style-guide/Label';
import theme from 'style-guide/Theme';
import Text from 'style-guide/Typography/Text';
import Title from 'style-guide/Typography/Title';

import UserAvatar from 'style-guide/NewUserAvatar';
import { deleteEntityModal } from 'utils/deleteModal';
import { toAwsBackgroundImage, toAwsCoverPicturesImage, toAwsUserImage } from 'utils/images';
import NewJoinedUser from './NewLearnerFooter/NewJoinedUser';
import NewNotJoinedUser from './NewLearnerFooter/NewNotJoinedUser';
import { handleCourseLabel } from './helper';
import LearningCommunityCardWrapper from './style/LearningCommunityCardWrapper';

const { LEARNING_COMMUNITY, course } = ENTITY_TYPES;
const { cleanUpPrice } = STRING_UTILS;
const { isPrivatePaid } = PAYMENT_TYPES;
const { organizer, member } = USER_COURSE_TYPES.userCourseRole;

const LearningCommunityCard = ({ data }) => {
  const {
    coverPhoto,
    user_favorite_course,
    id,
    name,
    shortDescription,
    price,
    paymentTypeId,
    paidPlanTypeId,
    subscriptionPlan,
    accessTypeId,
    accessCodeResalePrice,
    updatedAt,
    urlName,
    userType,
    percent,
    isExpired,
    isDraft,
    areParentEntitiesFree,
    rating,
    progressInfo,
    isArchive,
    userName,
    authorProfileImage,
    backgroundImage,
  } = data;

  const [isFavorite, setIsFavorite] = useState(user_favorite_course);
  const isAuth = useSelector(getUserIsAuth);
  const { userData: user } = useSelector((state) => state?.profileSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [addFavoriteCard] = useAddFavoriteCardMutation();
  const [removeFavoriteCard] = useRemoveFavoriteCardMutation();
  const [remove] = useRemoveLearningCommunitiesMutation();

  const status = getStatus({ percent, isExpired, isDraft });

  const hasEditAccess = USER_PERMISSION.hasPermission(
    course,
    USER_PERMISSION._CourseDetailsEdit,
    data?.user_courses?.[0]?.userTypeId
  );

  const hasEditPermission = USER_PERMISSION.hasPermission(
    ENTITY_TYPES.course,
    USER_PERMISSION._CourseManage,
    data?.user_courses?.[0]?.userTypeId
  );

  const role = hasEditAccess ? organizer : member;
  const learningCommunityUrl = `/${LEARNING_COMMUNITY.key}/${id}/${urlName}/${role}`;

  const removeFromFavorite = () => {
    removeFavoriteCard({ favoriteId: isFavorite?.id, type: getOldEntitiesType(LEARNING_COMMUNITY.key).plural }).then(
      () => setIsFavorite(null)
    );
  };

  const addToFavorite = () => {
    addFavoriteCard({ userId: user.id, entityId: id, type: getOldEntitiesType(LEARNING_COMMUNITY.key)?.plural }).then(
      (res) => setIsFavorite({ ...res.data })
    );
  };

  useEffect(() => {
    setIsFavorite(user_favorite_course);
  }, [user_favorite_course]);

  const isJoined = !!data.user_courses?.length;
  const isOrganization = LEARNING_COMMUNITY.key === getEntityName(LEARNING_COMMUNITY.key)?.plural.toLowerCase();
  const hasAccessCodePrice = isPrivatePaid({ accessTypeId, paymentTypeId });

  const entityPrice = isOrganization
    ? getOrgPrice({ paymentTypeId, price, paidPlanTypeId, subscriptionPlan })
    : cleanUpPrice({ string: price, symbol: '$' });

  const priceForEntity = hasAccessCodePrice ? `$${accessCodeResalePrice}` : entityPrice;

  const learningCommunityContentUrl = `/${LEARNING_COMMUNITY.key}/${id}/${urlName}/${organizer}`;

  const labelProps = useMemo(
    () =>
      handleCourseLabel(isArchive, status, progressInfo?.progressPercent, progressInfo?.courseComplete, hasEditAccess),
    [hasEditAccess, isArchive, progressInfo?.courseComplete, progressInfo?.progressPercent, status]
  );
  const handleClone = useEntityClone({ data, id, entityType: ENTITY_TYPES.LEARNING_COMMUNITY });
  const entityTypeUrl = `/${LEARNING_COMMUNITY.key}/${id}/${urlName}/${role}`;

  const itemClickHandle = (item) => {
    if (item === 'clone') {
      handleClone();
      return;
    }
    if (item === 'delete') {
      deleteEntityModal(remove, data.id, dispatch);
      return null;
    }
    if (item) {
      navigate(item);
    }
  };

  return (
    <LearningCommunityCardWrapper className='p--16'>
      {isAuth && !isJoined ? (
        <div className='heart'>
          <Button onClick={isFavorite ? removeFromFavorite : addToFavorite} variant='link'>
            {isFavorite ? (
              <NewHeartFilled width={30} height={30} className='full-heart' />
            ) : (
              <NewHeart width={30} height={30} className='empty-heart' />
            )}
          </Button>
        </div>
      ) : null}
      {hasEditAccess ? (
        <DropdownV2
          options={CARD_OPTIONS(id, urlName, userName, LEARNING_COMMUNITY.key, hasEditPermission)}
          space={2}
          onClickItem={(item) => itemClickHandle(item)}
        >
          <Settings width={16} height={16} />
        </DropdownV2>
      ) : null}
      <Button $block to={`${learningCommunityUrl}/homepage`} variant='text' className='p--0 navigate-button'>
        <div className='background-wrapper'>
          <Image
            src={(coverPhoto && toAwsCoverPicturesImage(coverPhoto)) || toAwsBackgroundImage(backgroundImage)}
            alt='no background'
            defaultImage={noBackground}
          />
        </div>
        <Title className='mt--16 mb--4 lc-name' variant={5}>
          {name}
        </Title>
        <Text $variant={2} className='course-description' color={theme().colors.gray[20]}>
          {shortDescription}
        </Text>
      </Button>
      <div className='row mv--16'>
        <div className='col-9 overflow align-items-center avatar-container'>
          <UserAvatar
            type='default'
            label={userName}
            imgSrc={authorProfileImage ? toAwsUserImage(authorProfileImage) : null}
          />
        </div>
        <div className='col-3 d-flex align-items-center justify-content-end'>
          {isJoined ? <Label type={labelProps?.type}>{labelProps?.text}</Label> : null}
        </div>
      </div>
      <DividerWithText className='mv--16' />
      {hasEditAccess ? (
        <NewAdminFooter
          lastChange={updatedAt}
          url={learningCommunityContentUrl}
          price={cleanUpPrice({ string: price, symbol: '$' })}
          accessCodeResalePrice={accessCodeResalePrice}
        />
      ) : null}
      {!hasEditAccess && isJoined ? (
        <NewJoinedUser
          userType={userType}
          percent={percent}
          status={status}
          EntityTypeUrl={entityTypeUrl}
          progressInfo={progressInfo}
        />
      ) : null}
      {!hasEditAccess && !isJoined ? (
        <NewNotJoinedUser
          priceForEntity={priceForEntity}
          areParentEntitiesFree={areParentEntitiesFree}
          rating={rating}
          type={LEARNING_COMMUNITY.key}
          urlName={urlName}
          id={id}
          name={name}
          paymentTypeId={paymentTypeId}
        />
      ) : null}
    </LearningCommunityCardWrapper>
  );
};

export default LearningCommunityCard;

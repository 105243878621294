// PeerTree
const peertree = {
  id: 1,
  colors: {
    primary: {
      800: '#1D5846',
      600: '#2B866B',
      400: '#52A180',
      200: '#86AE96',
      100: '#D9EAE0',
      50: '#E9F5EE',
    },
  },

  fonts: {
    mainFont: 'SF Pro Text Regular',
  },
};

// Boyens University
const boyens = {
  id: 2,
  colors: {
    primary: {
      800: '#7BA7C7',
      600: '#8DCFFF',
      400: '#ABDCFF',
      200: '#C9E8FF',
      100: '#E3F3FF',
      50: '#F1F9FF',
    },
  },
  font: 'Open-Sans',
};

// Hippo campus
const hippoCampus = {
  id: 3,
  colors: {
    // Todo: set different colors for each category
    primary: {
      800: '#B43814',
      600: '#E85124',
      400: '#F46E46',
      200: '#FF8F6E',
      100: '#FFAD95',
      50: '#FFE2D9',
    },
  },
  fonts: { mainFont: 'SF Pro Text Regular' },
};

// Political Islam
const politicalIslam = {
  id: 4,
  colors: {
    primary: {
      800: '#D9A958',
      600: '#FFB436',
      400: '#FFC666',
      200: '#FFD388',
      100: '#FFDDA5',
      50: '#FFEDCE',
    },
  },
  fonts: { mainFont: 'SF Pro Text Regular' },
};

// VU School of Nursing
const vanderbilt = {
  id: 5,
  colors: {
    primary: {
      800: '#D8AB4C',
      600: '#FFBD31',
      400: '#FFCD64',
      200: '#F7CF7B',
      100: '#FAE1AB',
      50: '#FFEFCF',
    },
  },
  fonts: {
    mainFont: 'SF Pro Text Regular',
    textFont: 'Roboto',
    titleFont: 'Lora',
  },
};

// Communicate to Connect
const c2c = {
  id: 6,
  colors: {
    primary: {
      800: '#558B2F',
      600: '#455C9A',
      200: '#C5E1A5',
      50: '#F1F8E9',
    },
  },
  fonts: { mainFont: 'SF Pro Text Regular' },
};

// PEAR Core University
const pear = {
  id: 7,
  colors: {
    primary: {
      800: '#336666',
      600: '#72D6D6',
      400: '#A7E8E8',
      200: '#BAEDED',
      100: '#CBEBEB',
      50: '#E3F2F2',
    },
  },
  fonts: { mainFont: 'SF Pro Text Regular', textFont: 'Roboto', titleFont: 'Lora' },
};

// Hrights MLK
const hrights = {
  id: 8,
  colors: {
    primary: {
      800: '#212738',
      600: '#283D7A',
      400: '#4D65A7',
      200: '#6083E8',
      100: '#BACCFF',
      50: '#E8EEFF',
    },
    textColor: 'white',
  },
  fonts: { mainFont: 'SF Pro Text Regular' },
};

// Nucleus
const nucleus = {
  id: 9,
  colors: {
    primary: {
      800: '#0088BB',
      600: '#39BEF0',
      400: '#71D8FF',
      200: '#A3E5FD',
      100: '#CDF1FF',
      50: '#E4F7FE',
    },
  },
  fonts: { mainFont: 'Gotham-Medium', textFont: 'Gotham-Medium', titleFont: 'Serifa' },
};

// Epic Challenge Foundation
const ecf = {
  id: 11,
  colors: {
    primary: {
      800: '#FF9D1E',
      600: '#FFB14B',
      400: '#F9C177',
      200: '#FFD8A5',
      100: '#FFE9CC',
      50: '#FFF4E5',
    },
  },
  fonts: {
    mainFont: 'SF Pro Text Regular',
    textFont: 'Roboto',
    titleFont: 'Lora',
  },
};

// Freddie Scott
const fsc = {
  id: 10,
  colors: {
    primary: {
      800: '#E78B03',
      600: '#FF9800',
      400: '#FFB445',
      200: '#FECF8A',
      100: '#FFDFB0',
      50: '#FFECD1',
    },
  },
  fonts: { mainFont: 'SF Pro Text Regular', textFont: 'Roboto', titleFont: 'Lora' },
};

// CyberSecure Mindset Academy
const csm = {
  id: 12,
  colors: {
    primary: {
      800: '#CA9005',
      600: '#F6B519',
      400: '#FECB50',
      200: '#FFD56F',
      100: '#FFE4A2',
      50: '#FDF9E5',
    },
    darkTextColor: '#0D1B29',
  },
  fonts: {
    mainFont: 'Roboto',
    textFont: 'Roboto',
    titleFont: 'Roboto',
  },
};

// Salon Spa Academy
const ssa = {
  id: 13,
  colors: {
    primary: {
      800: '#000000',
      600: '#424242',
      400: '#707070',
      200: '#8A8A8A',
      100: '#A6A6A6',
      50: '#CACACA',
    },
    textColor: 'white',
  },
  fonts: { mainFont: 'SF Pro Text Regular', textFont: 'Roboto', titleFont: 'Lora' },
};

/// workVibes
const workvibes = {
  id: 16,
  colors: {
    primary: {
      800: '#000000',
      600: '#000000',
      400: '#484848',
      200: '#7C7C7C',
      100: '#ABABAB',
      50: '#BDBDBD',
    },
    textColor: 'rgb(255, 255, 255)',
    darkTextColor: '#000000',
  },
  fonts: { mainFont: 'Lato' },
};

// TVGuestpert
const tvguest = {
  id: 14,
  colors: {
    primary: {
      800: '#F6B519',
      600: '#FFCE57',
      400: '#F2D388',
      200: '#FDE6AE',
      100: '#FFF0CC',
      50: '#FFF4D9',
    },
  },
  fonts: { mainFont: 'Lato', textFont: 'Roboto', titleFont: 'Lora' },
};

const me = {
  id: 14,
  colors: {
    primary: {
      800: '#209DFD',
      600: '#78C4FF',
      400: '#9DD4FF',
      200: '#BADDF8',
      100: '#DDF0FF',
      50: '#E9F5FF',
    },
  },
  fonts: { mainFont: 'MyriadPro-Regular', textFont: 'Roboto', titleFont: 'Lora' },
};

const spaces = {
  id: 15,
  colors: {
    primary: {
      800: '#CBA401',
      600: '#F9C800',
      400: '#FFD320',
      200: '#FFDE57',
      100: '#FFE88B',
      50: '#FFF3C2',
    },
  },
  fonts: { mainFont: 'Lato', textFont: 'Roboto', titleFont: 'Lora' },
};

const em = {
  id: 17,
  colors: {
    primary: {
      800: '#2A2A2A',
      600: '#333333',
      400: '#575757',
      200: '#7B7B7B',
      100: '#A1A1A1',
      50: '#C1C1C1',
    },
    textColor: 'rgb(255, 255, 255)',
  },
  fonts: { mainFont: 'Source Sans Pro', textFont: 'Source Sans Pro', titleFont: 'Klinic Slab Bold' },
};

const fba = {
  id: 18,
  colors: {
    primary: {
      800: '#324A64',
      600: '#4C6D92',
      400: '#6DB3E1',
      200: '#B4DCF4',
      100: '#D0EDFF',
      50: '#DEF2FF',
    },
    textColor: '#565656',
  },
  fonts: { mainFont: 'Raleway' },
};

const mh360 = {
  id: 19,
  colors: {
    primary: {
      800: '#002060',
      400: '#99A6BF',
      600: '#1D4597',
      200: '#CCD2DF',
      10: '#E5E9EF',
      50: '#F2F4F7',
    },
    textColor: 'rgb(255, 255, 255)',
  },
  fonts: { mainFont: 'SF Pro Text Regular' },
};
const saq = {
  id: 20,
  colors: {
    primary: {
      800: '#095B7F',
      600: '#0A6B96',
      400: '#9DC4D5',
      200: '#CEE1EA',
      100: '#E6F0F4',
      50: '#F3F8FA',
    },
    textColor: 'rgb(255, 255, 255)',
  },
  fonts: { mainFont: 'Lato', textFont: 'Oxygen' },
};

const customers = {
  peertree,
  boyens,
  hippoCampus,
  politicalIslam,
  vanderbilt,
  c2c,
  pear,
  hrights,
  nucleus,
  fsc,
  ecf,
  csm,
  ssa,
  workvibes,
  tvguest,
  spaces,
  me,
  em,
  fba,
  mh360,
  saq,
};

export default customers;

const DEFAULTS = {
  title: { max: 60, min: 3 },
  shortDescription: { max: 200, min: 3 },
  description: { max: 200, min: 3 },
  content: { max: 500, min: 3 },
  cpeCredit: { min: 1, max: 100 },
  companyDescription: { max: 250 },
  keywords: { max: 150 },
};

const ENTITIES = {
  COURSE: { ...DEFAULTS },
  LIBRARY: { ...DEFAULTS },
  ORGANIZATION: { ...DEFAULTS },
};

const COURSE_SECTIONS = {
  MAIN_CONTENT: {
    ...DEFAULTS,
  },
  INITIAL_THOUGHTS: {
    ...DEFAULTS,
  },
  EXPERT_RESOURCES: {
    ...DEFAULTS,
  },
  NEW_INSIGHTS: {
    ...DEFAULTS,
  },
  TESTS: {
    ...DEFAULTS,
    ANSWER: {
      LIST: { max: 10, min: 2 },
    },
    VALUE: {
      max: 200,
      min: 1,
    },
  },
};

const COURSE_CONTENT = {
  VOLUMES: {
    LIST: {
      min: 2,
    },
  },
  CHAPTERS: {
    LIST: {
      min: 1,
      max: 20,
    },
  },
};

const FILES = {
  name: { min: 1, max: 300 },
  size: { max: 524288000 }, // 500mb
};

// TODO: Add/Transfer limits here
const USER = {};
const PAYMENTS = {};

const ENTITIES_RATING = { REVIEWS: { max: 10 } };

const IMAGE = {
  backgroundImage: 1200,
  coverImage: 600,
  profileImage: 150,
  size: 1.5,
};

const INPUTS = {
  password: { min: 14 },
};

const SYSTEM_LIMITS = {
  DEFAULTS,
  ENTITIES,
  COURSE_SECTIONS,
  COURSE_CONTENT,
  USER,
  PAYMENTS,
  FILES,
  ENTITIES_RATING,
  IMAGE,
  INPUTS,
};

export default SYSTEM_LIMITS;
